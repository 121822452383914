<template>
  <v-col cols="12" :sm="cols" style="padding-top: 7px; padding-bottom: 7px">
    <v-row>
      <v-col cols="12" sm="5" :style="'text-align:' + align"
        ><p class="key">{{ title }}</p></v-col
      >
      <v-col cols="12" sm="7" :style="'text-align:' + align"
        ><p class="val">{{ value }}</p></v-col
      >
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: ["title", "value", "align", "cols"],
};
</script>

<style>
p {
  font-size: 1rem;
  margin-bottom: 5px !important;
}
.key {
  font-weight: normal;
  color: #707070 !important;
}
</style>

<template>
  <div class="reservation">
    <div class="content">
      <v-card class="mx-auto form-card">
        <v-row>
          <v-col cols="12" sm="12" class="right-col">
            <v-card-text>
              <v-row class="mx-0">
                <v-col class="text-center">
                  <h1 class="main-title">فاتورة ضريبية</h1>
                  <v-img
                    v-if="invoiceData.salon_logo"
                    :src="invoiceData.salon_logo"
                    class="logo"
                    width="80px"
                    style="margin: 20px auto 0 auto"
                  ></v-img>
                  <v-img
                    v-else
                    src="../assets/icon.png"
                    class="logo"
                    width="80px"
                    style="margin: 20px auto 0 auto"
                  ></v-img>
                  <h1 class="mb-4 sub-title">
                    صالون {{ invoiceData.salon_name }}
                  </h1>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row class="justify-center">
                <v-col cols="12" sm="12">
                  <div class="page">
                    <v-row>
                      <invoiceItem
                        :title="'التاريخ'"
                        :value="invoiceData.reservation_date"
                        align="right"
                        cols="6"
                      />
                      <invoiceItem
                        :title="'زمن الحجز'"
                        :value="invoiceData.reservation_time"
                        align="left"
                        cols="6"
                      />
                    </v-row>
                    <v-row>
                      <invoiceItem
                        :title="'الرقم الضريبي'"
                        :value="invoiceData.tax_number"
                        align="right"
                        cols="6"
                      />
                      <invoiceItem
                        :title="'الفرع'"
                        :value="invoiceData.branch_name"
                        align="left"
                        cols="6"
                      />
                    </v-row>
                    <hr style="margin: 15px 0" />
                    <v-row>
                      <invoiceItem
                        :title="'إسم العميل'"
                        :value="invoiceData.client_name"
                        align="right"
                        cols="6"
                      />
                      <!-- </v-row>
                    <v-row> -->
                      <v-col
                        cols="12"
                        sm="6"
                        style="padding-top: 10px; padding-bottom: 10px"
                      >
                        <v-row>
                          <v-col cols="12" sm="5" style="text-align: left"
                            ><p class="key">رقم الجوال</p></v-col
                          >
                          <v-col cols="12" sm="7" style="text-align: left"
                            ><p class="val" dir="ltr">
                              {{ invoiceData.client_mobile }}
                            </p></v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <invoiceItem
                        :title="'الجلسات'"
                        :value="invoiceData.sessions"
                        align="right"
                        cols="6"
                      />
                      <!-- </v-row>
                    <v-row> -->
                      <invoiceItem
                        :title="'مقر الحجز'"
                        :value="
                          invoiceData.reservation_at == 'salon'
                            ? 'الصالون'
                            : 'المنزل'
                        "
                        align="left"
                        cols="6"
                      />
                    </v-row>
                    <v-row>
                      <invoiceItem
                        :title="'عدد الجلسات'"
                        :value="invoiceData.sessions_count"
                        cols="6"
                        align="right"
                      />
                    </v-row>
                    <hr style="margin: 15px 0" />
                    <v-row>
                      <invoiceItem
                        :title="'إجمالي سعر الجلسات'"
                        :value="invoiceData.sub_total"
                        cols="12"
                      />
                    </v-row>
                    <v-row>
                      <invoiceItem
                        v-if="invoiceData.home_service_fees"
                        :title="'مصاريف خدمة المنزل'"
                        :value="
                          invoiceData.home_service_fees
                            ? invoiceData.home_service_fees
                            : '-'
                        "
                        cols="12"
                      />
                    </v-row>
                    <v-row>
                      <invoiceItem
                        :title="'ضريبة القيمة المضافة'"
                        :value="invoiceData.vat_amount"
                        cols="12"
                      />
                    </v-row>
                    <v-row>
                      <invoiceItem
                        :title="'الإجمالي'"
                        :value="invoiceData.total"
                        cols="12"
                      />
                    </v-row>
                    <v-img
                      :src="invoiceData.qr"
                      class="logo"
                      width="180px"
                      style="margin: 20px auto 0 auto"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="text-left d-block">
              <v-btn
                class="main-btn"
                large
                @click="downloadPdf"
                style="letter-spacing: 0"
                >تحميل الفاتورة PDF</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as VueGoogleMaps from "vue2-google-maps";
import invoiceItem from "../components/invoiceItem.vue";
export default {
  name: "invoice",
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      time: null,
      menu2: false,
      invoiceData: {},
      loading: true,
      center: { lat: 24.7136, lng: 46.6753 },
      markers: [
        {
          position: { lat: 24.7136, lng: 46.6753 },
        },
      ],
      showMap: true,
    };
  },
  methods: {
    getInvoiceData() {
      this.$http
        .get(
          this.base_url + "/salon/invoice/" + this.$route.params.invoice_number
        )
        .then((response) => {
          if (response.data.data == null) {
            this.$router.push({ path: "/404" });
          } else {
            this.invoiceData = response.data.data;
            this.loading = false;
          }
        });
    },
    downloadPdf() {
      window.open(this.invoiceData.pdf_url, "_blank").focus();
    },
  },
  mounted() {
    this.getInvoiceData();
  },
  computed: {
    ...mapGetters(["base_url"]),
    google: VueGoogleMaps.gmapApi,
  },
  components: {
    invoiceItem,
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "jannaLtBold";
  src: local("jannaLtBold"),
    url(../assets/fonts/JannaLTBold/JannaLTBold.ttf) format("truetype");
}
$second-color: #ff7dac;
* {
  font-family: "jannaLtBold";
}
.white-text {
  color: #fff;
}
.gray-text {
  color: #888888 !important;
}
.black-text {
  color: #000 !important;
}
.main-title {
  color: #a53860;
  font-size: 2rem !important;
  margin: 10px auto 40px auto;
}

.sub-title {
  font-size: 1.5rem !important;
  margin-top: 5px;
}

.content {
  .location {
    font-size: 1rem;
  }

  .form-card {
    background-color: #fff !important;
    // box-shadow: none !important;
    margin-top: 40px;
    border-radius: 5px 5px 30px 5px;
    border-top: 12px solid #a53860;
  }
}
.rounded-input {
  border-radius: 20px;
}
.countrycode {
  direction: ltr !important;
  text-align: center !important;
  padding-right: 0 !important;
}
.countrycode-col {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.countrycode-col .v-text-field__slot input {
  text-align: center !important;
}
.main-btn {
  background-color: #a53860 !important;
  color: #ffffff !important;
  border-radius: 5px 5px 20px 5px;
  // width: 150px;
  font-size: 1rem !important;
  margin-left: 10px;
}

.text-center {
  text-align: center !important;
}
.page p {
  font-size: 1rem;
}
.page .key {
  font-weight: normal;
  color: #707070 !important;
}
@media (min-width: 960px) {
  .form-card {
    max-width: 40%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}
</style>
